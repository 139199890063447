<div class="bg-gray-100 pt-3" id="scrollIntoTop">
  <div style="width: 98%;" class="ml-auto mr-auto">
    <div class="">
      <app-claim-advance-filters 
        [hidden]="!filterIsOpen" 
        [filters]="filters" 
        [loading]="loading" 
        [sources]="_sources"
        [users]="users" 
        (onSet)="updateFilter($event)" 
        (onReset)="resetFilter($event)"
        (newClaim)="newClaim()"
      >
      </app-claim-advance-filters>
    </div>
    <div class="claims-app-filters">
      <div class="flex justify-between items-center" style="background: #f2f3f8">
        <div>
          <app-filters 
            [status]="_statuses" 
            [filters]="filters" 
            (filterSet)="updateFilter($event)"
          ></app-filters>
          <div class="mt-3" *ngIf="filters?.status">
            <app-filters  
              [status]="_subStatuses || []" 
              [property]="'subStatus'"
              [filters]="filtersWidthoutStatus" 
              (filterSet)="updateFilter($event)"
              ></app-filters>
          </div>
        </div>
        <div class="filters_selected text-right">
          <div style="width: fit-content; margin-left: auto;" class="flex items-center">
            <!-- <span class="ml-auto mr-3" style="color: rgba(0, 0, 0, 0.555);"><span class="font-bold" style="color: rgba(0, 0, 0, 0.452);">{{ localSelectedOrders?.length }}</span> Selected</span> -->
           <div class="mr-3 limit_select">
            <app-select
              [keyName]="{key:'key', name:'name'}"
              [list]="limitOptions"
              [value]="filters?.limit ? filters?.limit.toString() : '25'"
              [label]="'Limit'"
              [options]="{noFirstOption: true}"
              (onSave)="updateFilter({ limit: $event })"
            ></app-select>
          </div>
           <span class="ml-auto mr-3" style="color: rgba(0, 0, 0, 0.555);">on <span class="font-bold" style="color: rgba(0, 0, 0, 0.452);">{{ totalCount }}</span> total</span>  
          </div>
        </div>
      </div>
    </div>
    <div class="claim-list-wrapper">
      <div class="head-loadboard flex items-center">
        <div class="header-title claims-wr">
          Claim
          <button 
          (click)="onSort({dir: filters?.dir == 'asc' ? 'desc' : 'asc', prop: 'autoIncr'})" 
          class="filter-sort-btn"
        >
          <i class="fa-solid text-gray-500" 
            [ngClass]="{
              'fa-chevron-down': (filters?.prop == 'autoIncr' && filters?.dir == 'desc') || filters?.prop !== 'autoIncr',
              'fa-chevron-up': filters?.prop == 'autoIncr' && filters?.dir == 'asc'
              }"
          ></i>
        </button>
        </div>
        <div class="header-title status-wr pl-0">
          Status 
          <button 
            (click)="onSort({dir: filters?.dir == 'asc' ? 'desc' : 'asc', prop: 'status'})" 
            class="filter-sort-btn"
          >
            <i class="fa-solid text-gray-500" 
              [ngClass]="{
                'fa-chevron-down': (filters?.prop == 'status' && filters?.dir == 'desc') || filters?.prop !== 'status',
                'fa-chevron-up': filters?.prop == 'status' && filters?.dir == 'asc'
                }"
            ></i>
          </button>
        </div>
        <div class="header-title customer-wr">
          Customer
          <button 
            (click)="onSort({dir: filters?.dir == 'asc' ? 'desc' : 'asc', prop: '_claimant'})" 
            class="filter-sort-btn"
          >
            <i class="fa-solid text-gray-500" 
              [ngClass]="{
                'fa-chevron-down': (filters?.prop == '_claimant' && filters?.dir == 'desc') || filters?.prop !== '_claimant',
                'fa-chevron-up': filters?.prop == '_claimant' && filters?.dir == 'asc'
                }"
            ></i>
          </button>
        </div>
        <div class="header-title carrier-wr">
          Carrier
          <button 
            (click)="onSort({dir: filters?.dir == 'asc' ? 'desc' : 'asc', prop: 'carrier'})" 
            class="filter-sort-btn"
          >
            <i class="fa-solid text-gray-500" 
              [ngClass]="{
                'fa-chevron-down': (filters?.prop == 'carrier' && filters?.dir == 'desc') || filters?.prop !== 'carrier',
                'fa-chevron-up': filters?.prop == 'carrier' && filters?.dir == 'asc'
                }"
            ></i>
          </button>
        </div>
        <div class="header-title vehicles-wr">
          Vehicles
          <button 
            (click)="onSort({dir: filters?.dir == 'asc' ? 'desc' : 'asc', prop: '_vehicles'})" 
            class="filter-sort-btn"
          >
            <i class="fa-solid text-gray-500" 
              [ngClass]="{
                'fa-chevron-down': (filters?.prop == '_vehicles' && filters?.dir == 'desc') || filters?.prop !== '_vehicles',
                'fa-chevron-up': filters?.prop == '_vehicles' && filters?.dir == 'asc'
                }"
            ></i>
          </button>
        </div>
        <div class="header-title dates-wr">
          Dates
          <button 
            (click)="onSort({dir: filters?.dir == 'asc' ? 'desc' : 'asc', prop: 'incidentDate'})" 
            class="filter-sort-btn"
          >
            <i class="fa-solid text-gray-500" 
              [ngClass]="{
                'fa-chevron-down': (filters?.prop == 'incidentDate' && filters?.dir == 'desc') || filters?.prop !== 'incidentDate',
                'fa-chevron-up': filters?.prop == 'incidentDate' && filters?.dir == 'asc'
                }"
            ></i>
          </button>
        </div>
        <div class="header-title tags-wr">
          Tags
          <button 
            (click)="onSort({dir: filters?.dir == 'asc' ? 'desc' : 'asc', prop: 'source'})" 
            class="filter-sort-btn"
          >
            <i class="fa-solid text-gray-500" 
              [ngClass]="{
                'fa-chevron-down': (filters?.prop == 'source' && filters?.dir == 'desc') || filters?.prop !== 'source',
                'fa-chevron-up': filters?.prop == 'source' && filters?.dir == 'asc'
                }"
            ></i>
          </button>
        </div>
      </div>
      <div class="">
        @for (row of currentClaims; track $index) {
          <div class="main-item-loadboard flex items-center">
            <div class="main-claim claims-wr">
              <a href="#" [routerLink]="['/claims', row._id]" *ngIf="!row.deleted"
                routerLinkActive="router-link-active" style="color: #1E429F;" class="res-list-link size_large font-semibold">
                {{ row.autoIncr }}
              </a>
              <a
                *ngIf="row?._order?._id"
                href="#"
                target="_blank"
                class="fa-external_icon"
                [routerLink]="['/loadboard', row?._order?._id]" 
                [popper]="quoteNumber" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
              >
                <i class="fas fa-external-link-alt"></i>
              </a>
              <popper-content #quoteNumber class="z-50">
                <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info">
                  <div class="bg-white rounded-lg">
                    <div class="px-2 py-2">
                      <p class="text-sm font-poppins font-semibold text-center">View in Order {{row?._order?.quoteNumber}}<span *ngIf="row?._order?.ordering">.{{ row?._order?.ordering }}</span></p>
                    </div>
                  </div>
                </div>
              </popper-content>

              <div *ngIf="row?._dispatches && row?._dispatches.length > 0" [popper]="dispatches" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
                class="claim_dispatcher size_small"
                [ngClass]="{ 'text-red-800 bg-red-100': row?.dispatcherAtFault, 'border border-gray-300 text-gray-900': !row?.dispatcherAtFault }"
              >
                {{ row?._dispatches[0]?.name | shrinkName }}
              </div>
              <popper-content #dispatches class="z-50">
                <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info">
                  <div class="bg-white rounded-lg">
                    <div class="px-2 py-2">
                      <p class="text-sm font-poppins font-semibold text-center">{{row?._dispatches?.length ? row?._dispatches[0]?.name : null}}</p>
                    </div>
                  </div>
                </div>
              </popper-content>

                <i class="fa fa-dollar-sign paid-status-icon text-gray-600" [ngClass]="{
                  'text-green-800': row?.transportPaid,
                  'text-red-800': row?.realPaidAmount > 0 && row?.realPaidAmount < row?.paidAmount
                }"></i>
            </div>
            <div class="main-status status-wr">
              <div class="">
                <span class="size_small claim-list_status font-medium border border-gray-300">{{ row?.status }}</span>
                <i *ngIf="row?.stage" class="fas fa-fire claim-stage"
                  [ngClass]="['stage', 'stage-' + row.stage.toLocaleLowerCase()]"></i>
              </div>
              <div *ngIf="row.subStatus">
                <span class="claim-list_status size_small font-medium" [ngClass]="{
                  'bg-red-100 text-red-800': row?.subStatus === 'ACCEPTED',
                  'bg-green-100 text-green-900': row?.subStatus === 'DENIED',
                  'bg-purple-100 text-purple-900': row?.subStatus === 'IN_REVIEW',
                }">{{
                  getSubStatusName(row.subStatus)
                  }}</span>
              </div>
              <span class="text-muted"> {{ row?.updatedAt | date: 'MM/dd/yy' }}</span>
              <span *ngIf="row.status !== 'CLOSED' && row?._daysActive" [popper]="claimDaysActive" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true" class="daysActive-item size_small font-medium bg-red-100 text-red-800">
                {{ row?._daysActive }}
              </span>
              <popper-content #claimDaysActive class="z-50">
                <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info">
                  <div class="bg-white rounded-lg">
                    <div class="px-2 py-2">
                      <p class="text-sm font-poppins font-semibold text-center">Days Active</p>
                    </div>
                  </div>
                </div>
              </popper-content>
            </div>
            <div class="main-customer customer-wr">
              <a *ngIf="row?._claimant" [popper]="claimant" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true" class="size_small" [routerLink]="[]" (click)="openContactInfo(row?._claimant?._id)"
                >{{ row?._claimant?.name }}
              </a>
              <popper-content #claimant class="z-50">
                <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info-large">
                  <div class="bg-white rounded-lg">
                    <div class="px-2 py-2">
                      <p class="text-sm font-poppins font-semibold text-center">Click to show full information about {{ row?._claimant?.name }}</p>
                    </div>
                  </div>
                </div>
              </popper-content>
            </div>
            <div class="main-carrier carrier-wr">
              <div *ngIf="row?._carrier">
                <a class="carrier_name" [routerLink]="[]" (click)="openContactInfo(row?._carrier?._id)"
                  [popper]="carrier" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
                  [ngClass]="{ 'text-danger': row?.carrierAtFault }"
                >
                  {{ row?._carrier?.name }}
                </a>
                <popper-content #carrier class="z-50">
                  <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info-large">
                    <div class="bg-white rounded-lg">
                      <div class="px-2 py-2">
                        <p class="text-sm font-poppins font-semibold text-center">Click to show full information about {{ row?._carrier?.name }}</p>
                      </div>
                    </div>
                  </div>
                </popper-content>
                @for(d of row?._drivers; track $index){
                  <div>
                    <div class="flex items-center">
                      <span class="driver_name">{{ d.name }}</span>
                      <div *ngIf="row?.driversFault ? row?.driversFault.includes(d._id) : false" class="driversFault-wr">
                        <i class="fa-solid fa-info driversFault cursor-pointer" [popper]="drivers" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"></i>
                      </div>
                      <popper-content #drivers class="z-50">
                        <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info">
                          <div class="bg-white rounded-lg">
                            <div class="px-2 py-2">
                              <p class="text-sm font-poppins font-semibold text-center">At Fault</p>
                            </div>
                          </div>
                        </div>
                      </popper-content>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div class="main-vehicles vehicles-wr relative">
              @for(vehicle of row?._vehicles?.slice(0, row?._displayedCars) | sort:'createdAt'; track $index){
                <div>
                  <div class="flex items-center size_large font-bold vehicles-list" [ngStyle]="{'width': row?._vehicles?.length > 3 ? '90%' : null}">
                    <span>{{ vehicle?.year }}</span>
                    <span class="ml-1">{{ vehicle?.make }}</span>
                    <span class="ml-1">{{ vehicle?.model }}</span>
                  </div>
                  <span title="'INOP'"
                    style="color: #f4516c; display: inline-block; width: 10px; height: 10px; margin-right: 4px"
                    *ngIf="!vehicle?.isOperable" [ngClass]="['.m-badge', '.badge-style']">
                    <i class="fa-solid fa-car-burst" style="font-size: 10px; position: absolute"></i>
                  </span>
                  <span *ngIf="row?.isEnclosed" [ngClass]="['m-badge', 'badge-style', 'badge-encl']"> ENCL</span>
                  <span *ngIf="row?.serviceOption === 'Expedited'" [ngClass]="['m-badge', 'badge-style', 'badge-status-on-hold']">
                    EXP</span>
                </div>
              }
              <!-- <div *ngIf="row?._vehicles?.length > 1" class="grow-actions">
                <small class="text-muted">
                  <span class="badge badge-lg badge-outline badge-dark">{{ row?._vehicles?.length }}</span>
                </small> *ngIf="row?._vehicles.length > 3"
              </div> -->
              <ng-container>
                @if(row?._vehicles?.length > 3 && row?._displayedCars === 3){
                  <button (click)="toggleListVehicles(row);" class="vehiclesListBtn mr-2" style="z-index: 5;">
                    + {{row?._vehicles?.length - 3}}
                  </button>
                }@else if(row?._vehicles?.length > 3 && row?._displayedCars > 3){
                  <button (click)="toggleListVehicles(row)" class="vehiclesClose mr-2" style="z-index: 5;">
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                }
              </ng-container>
            </div>
            <div class="main-dates dates-wr">
              <div class="">
                <div *ngIf="row?.incidentDate" class="flex items-center">
                  <span class="claim_date_i size_small border border-gray-300 text-gray-900" [popper]="incident" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true">I</span>
                  <popper-content #incident class="z-50">
                    <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info">
                      <div class="bg-white rounded-lg">
                        <div class="px-2 py-2">
                          <p class="text-sm font-poppins font-semibold text-center">Incident Date</p>
                        </div>
                      </div>
                    </div>
                  </popper-content>
                  <span class="ml-1 size_small" style="color: #5E6876;">{{row.incidentDate | date: 'MM/dd': 'UTC'}}</span>
                </div>
                <div  *ngIf="row?.createdAt" class="mt-2 flex items-center">
                  <span class="claim_date_c size_small border border-gray-300 text-gray-900" title="Created Date" [popper]="created" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true">C</span>
                  <popper-content #created class="z-50">
                    <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info">
                      <div class="bg-white rounded-lg">
                        <div class="px-2 py-2">
                          <p class="text-sm font-poppins font-semibold text-center">Created Date</p>
                        </div>
                      </div>
                    </div>
                  </popper-content>
                  <span class="ml-1 size_small" style="color: #5E6876;">{{row.createdAt | date: 'MM/dd': 'UTC'}}</span>
                </div>
              </div>
            </div>
            <div class="tags-wr p-0">
              @if (row?.source){
                <div class="main-tags">
                  @for(source of row?.source?.slice(0, 4); track $index){
                    <div>
                      <span class="main-tags-item">{{getSourceName(source)}}</span>
                    </div>
                  }
                  @if(row?.source?.length > 4){
                    <button class="main-tags-item" [popper]="allTags" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">...</button>
                    <popper-content #allTags class="z-50">
                      <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 w-96">
                        <div class="bg-white rounded-lg flex flex-wrap gap-2  p-2">
                          @for(source of row?.source?.slice(4, row?.source?.length); track $index){
                            <div>
                              <span class="main-tags-item">{{getSourceName(source)}}</span>
                            </div>
                          }
                        </div>
                      </div>
                    </popper-content>
                  }
                  
                </div>
              }
            </div>
          </div>
        }
        <div class="claims-pagination">
          <app-pagination 
            *ngIf="currentClaims?.length" 
            (pageChange)="setPage($event)" 
            [total]="totalCount" 
            [limit]="filters?.limit" 
            [options]="{white: true}"
            [currentPage]="filters?.page"
          ></app-pagination>
        </div>

    </div>
  </div>
</div>